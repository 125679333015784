<template>
    <div class="detail" v-if="appInfo">
        <div class="topbar">
            <div class="title">{{appInfo.name}}</div>
            <div class="info">
                <span>v{{appInfo.version_name}}</span>
                <span class="divide">/</span>
                <span>{{appInfo.size}}</span>
                <span class="divide">/</span>
                <span>{{appInfo.upload_time}}</span>
                <span class="divide">/</span>
                <span>{{appInfo.user_name}}</span>
            </div>
        </div>
        <div class="content">
            <div class="download">
                <div class="">
                    <div class="img-container wh120">
                        <img :src="appInfo.icon || require('@/assets/whrp.png')" alt="">
                    </div>
                    <div class="mt10">
                        <el-button size="small" type="success" @click="download">点击安装<i class="ml5 el-icon-download"></i></el-button>
                    </div>
                    <el-tag size="mini" class="mt10" type="success" effect="dark">适用于{{appInfo.platform}}设备</el-tag>
                </div>
                <div class="line"></div>
                <div>
                    <div class="mb15">或者用手机扫描下面的二维码安装</div>
                    <div class="img-container wh150">
                        <QrCode :pcbaUrl="appPath"></QrCode>
                    </div>
                    <div class="mt10">
                        <el-button size="small" type="success" @click="copyClick">复制链接</el-button>
                    </div>
                </div>
            </div>
            
            <div class="update-info">
                <div class="update-title">更新说明</div>
                
                <el-card>
                    <div class="update-text" v-html="appInfo.explain"></div>
                </el-card>
            </div>

            <div class="table-container" v-if="historyList.length">
                <div class="table-title">历史版本</div>
                <el-timeline>
                    <el-timeline-item :hide-timestamp="true" v-for="item in historyList" :key="item.id" size="large">
                        <div class="timestamp" @click="toDetail(item)">
                            {{ item.version_name }}
                        </div>
                        <el-card>
                            <div class="explain"  v-html="item.explain"></div>
                            <p class="author">{{item.user_name}} 提交于 {{item.upload_time}}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
    </div>
</template>

<script>
import { getAppDetail , getAppDetailByName , getHistoryList } from '@/api/appAPI.js';
import QrCode from '@/common/qrCode';
import { copyText } from '@/utils/handle.js';

export default {
    components: {
        QrCode
    },
    data(){
        return {
            appInfo: null,
            historyList: [],
            appPath: '',
        }
    },
    computed: {
        id(){
            return this.$route.query.id;
        },
    },
    watch: {
        $route: {
            handler(val){
                this.handleParams();
            },
            immediate: true
        }
    },
    created(){
        // console.log(this.$route);
    },
    methods: {
        async handleParams(){
            const { unique_name , version_name } = this.$route.params;
            let appInfo;
            if(unique_name){
                appInfo = await getAppDetailByName({ unique_name , version_name });
                if(!appInfo){
                    this.$router.replace({
                        name: 'notFound'
                    })
                    return;
                }
            }else{
                const id = this.$route.query.id;
                if(id){
                    appInfo = await getAppDetail({ id });
                }else{
                    this.$message.error('参数id未传');
                }
            }
            if(appInfo){
                appInfo.size = (appInfo.size / 1024 / 1024).toFixed(2) + 'MB';
                appInfo.upload_time = Date.dateFormat(appInfo.upload_time);
                this.appInfo = appInfo;

                getHistoryList({ id: this.appInfo.id }).then(res => {
                    res.forEach(item => item.upload_time = Date.dateFormat(item.upload_time))
                    this.historyList = res;
                })

                const regex = /^(https?:\/\/[^\/?#]+)/;  
                const match = this.appInfo.url.match(regex);
                if(match){
                    const path1 = match[0];
                    const path2 = location.pathname + location.search;
                    this.appPath = path1 + path2;
                     
                }
            }
            
        },
        download(){
            const a = document.createElement('a');
            a.href = this.appInfo.url;
            a.download = this.appInfo.file_name;
            a.click();
        },
        toDetail(data){
            this.$router.push({
                name: 'Detail',
                query: {
                    id: data.id
                }
            })
        },
        copyClick(){

            copyText(this.appPath);
            this.$message.success('地址复制成功!');

        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.detail{
    // height: 100vh;
    margin: 0 auto;
    .topbar{
        width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        position: sticky;
        top: 0;
        border-bottom: 2px dotted #eee;
        z-index: 10;
        .title{
            font-size: 36px;
            font-weight: bold;
        }
        .info{
            height: 36px;
            background-color: #f5f5f5;
            padding: 0 15px;
            line-height: 36px;
            // color: #333;
            .divide{
                margin: 0 10px;
                color: #ccc;
            }
        }
    }
    .content{
        width: 1200px;
        margin: 0 auto;
    }
    .download{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 0;
        > div{
            display: flex;
            flex-direction: column;
            align-items: center;
            .apply{
                margin-top: 10px;
            }
        }
        .img-container{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-width: 100%;
                max-height: 100%;
            }

        }
        .wh120{
            width: 120px;
            height: 120px;
        }
        .wh150{
            width: 150px;
            height: 150px;            
        }
        .line{
            width: 100%;
            margin: 15px 0;
        }
    }
    .line{
        border-bottom: 2px dotted #eee;
    }
    .update-info{
        // border: 1px solid #eee;
        // background-color: #fafafa;
        min-height: 100px;
        // padding: 20px;
        box-sizing: border-box;
        .update-title{
            font-size: 18px;
            margin: 10px 0;
            height: 40px;
            line-height: 40px;
        }
        .update-text{
            line-height: 1.6;
            white-space: pre;
        }
    }
    .table-container{
        margin-top: 15px;
        width: 100%;
        .table-title{
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            margin-bottom: 10px;
            // background-color: #bbb;
            // color: #fff;
        }
        .timestamp{
            padding-top: 4px;
            margin-bottom: 8px;
            display: inline-block;
            font-size: 16px;
            cursor: pointer;
            color: #337ab7;
        }
        .explain{
            white-space: pre;
            line-height: 1.4;
            color: #999;
        }
        .author{
            margin-top: 25px;
            color: #999;
        }
    }
}
.center{
    display: flex;
    justify-content: center;
}
</style>