export function deepAssign(target,source){          //  深度合并对象
    // console.log(target,source);
    // debugger
    // console.log(JSON.parse(JSON.stringify(target)),source);
    if(source.constructor === Object){
        
        if(target.constructor === Object){
            Object.keys(source).forEach(sourceKey => {
                // debugger
                const targetItem = target[sourceKey];
                const sourceItem = source[sourceKey];
                const targetType = targetItem && targetItem.constructor;
                const sourceType = sourceItem && sourceItem.constructor;
    
                if(targetType === Object && sourceType === Object){
                    deepAssign(targetItem,sourceItem);                  //  都为对象的时候才会进行递归合并
                }else if(targetType === Array && sourceType === Array){
                    deepAssign(targetItem,sourceItem);
                }else {
                    if(source[sourceKey]){
                        target[sourceKey] = source[sourceKey];
                    }
                }
            })
        }else{
            target = source;
        }
        return target;
    }else if(source.constructor === Array){
        if(target.constructor === Array){
            for (let i = 0; i < source.length; i++) {
                let targetItem = target[i];
                const sourceItem = source[i];
                const targetItemType = targetItem && targetItem.constructor;
                const sourceItemType = sourceItem && sourceItem.constructor;
                
                if(targetItemType === Object && sourceItemType === Object){
                    deepAssign(targetItem,sourceItem);
                }else if(targetItemType === Array && sourceItemType === Array){
                    deepAssign(targetItem,sourceItem);
                }else{
                    target.splice(i,0,sourceItem);
                    // target[i] = sourceItem;
                }
            }
        }else{
            target = source;
        }
    }
}

export function debounce(func, wait) {  
    let timeout; // 注意这里使用了let而不是const，因为我们需要修改它  
  
    // 返回一个匿名函数，这个函数将作为防抖后的函数被调用  
    return function() {  
        const context = this; // 保存当前调用上下文  
        const args = arguments; // 保存传递给函数的参数  
  
        // 清除之前可能存在的定时器  
        clearTimeout(timeout);  
  
        // 设置一个新的定时器，在wait毫秒后执行func函数  
        timeout = setTimeout(() => {  
            func.apply(context, args); // 使用apply来确保func在正确的上下文和参数下执行  
        }, wait);  
    };  
}  

export function copyText(str) {
    str = str.replace(/\s/g, '');
    let input = document.createElement('input');
    input.style.opacity = '0';
    input.value = str;
    document.body.appendChild(input);
    input.select();
    const copyResult = document.execCommand('copy');
    document.body.removeChild(input);
    return copyResult;
}